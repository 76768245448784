import { defineStore } from 'pinia'
import {getUser, getBalance, logout, authStatus} from '@/http'
import { showToast } from 'vant';

export const useUserStore = defineStore('user', {
  state: () => ({
    userinfo: null,
    token: null,
    walletBalance: null,
    symbolData: null,
    lang: 'en_US',
    isAuth: false,
    notice: null,
  }),
  actions: {
    setNotice(data) {
      this.notice = data
    },
    setLang(data) {
      this.lang = data
    },
    setSymbolData(data) {
      this.symbolData = data
    },
    setUserinfo(data) {
      this.userinfo = data
    },
    setToken(data) {
      this.token = data
    },
    updateUser() {
      return new Promise((resolve, reject) => {
        getUser().then(({data}) => {
          this.userinfo = data
          resolve(data)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getBalance() {
      return new Promise((resolve, reject) => {
        getBalance().then(({data}) => {
          this.walletBalance = data
          resolve(data)
        }).catch(err => {
          reject(err)
        })
      })
    },
    logout() {
      this.userinfo = null
      this.token = null
      this.walletBalance = null
      logout().then(() => {
        showToast('退出成功')
      })
    },
    getAuthState() {
      authStatus().then(({data}) => {
        this.isAuth = data === 1
      })
    }
  },
  persist: true,
})
